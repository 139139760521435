
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout/components/Layout"
import { useAuth } from "../services/AuthProvider"
import NotAuthenticated from '../components/shared/notAuthenticated/components/NotAuthenticated'
import Loading from "../components/shared/loading/components/Loading"

const IndexPage = ({ pages }) => {
  const { isAuthenticated, isCheckingAuthState } = useAuth();
  return (
    <Layout>
      {isCheckingAuthState ? (
        <Loading/>
      ) : isAuthenticated ? (
        <div dangerouslySetInnerHTML={{__html: pages[0].html }}></div>
      ) : (
        <NotAuthenticated/>
      )}
    </Layout>
  )
}

export default () => {
  const { content } = useStaticQuery(GetAllWikiContent)
  const pages = content.edges.map((edge) => edge.node);
  return <IndexPage pages={pages}/>
}

const GetAllWikiContent = graphql`
  query GetTitlePage {
    content: allMarkdownRemark(sort: {fields: fileAbsolutePath }) {
      edges {
        node {
          html
          fileAbsolutePath
          fields {
            slug
          }
        }
      }
    }
  }
`



